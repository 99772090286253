// This is a component that can be used to display a date/time in the user's local timezone.
// input is a UTC date/time string, e.g. "2021-08-01T00:00:00" but lacking the Z for some reason

const LocalDateTime = ({ date }: { date: string }) => {

    const localDate = new Date(date + 'Z').toLocaleDateString(
        'en-au',
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
      );

    return <>{localDate}</>

    // return (
    //     <>
    //     {date &&
    //         Moment.utc(date.substring(0, 19))
    //         .tz(Moment.tz.guess())
    //         .format("DD MMM YYYY HH:mm")}
    //     </>
    // );
    }

export default LocalDateTime;
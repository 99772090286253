import React, { useState, useEffect, FC } from "react";
import { Profile } from "../auth/authContext";

export const ProfileComplete: FC<{
  profile: Profile;
}> = ({ profile }) => {
  const [tasks, setTasks] = useState<string[]>([]);

  useEffect(() => {
    var t = [];

    if (profile.vehicles.length === 0) {
      t.push("Add a vehicle");
    }

    if (
      profile.parker.firstName === null ||
      profile.parker.firstName.trim() === "" ||
      profile.parker.lastName === null ||
      profile.parker.lastName.trim() === "" ||
      profile.parker.mobile === null ||
      profile.parker.mobile.trim() === "" ||
      profile.parker.notificationType === null
    ) {
      t.push("Complete your profile (see Details tab)");
    }

    if (profile.parker.agreeTermsConditions === null) {
      t.push("Agree to Terms and Conditions (see Details tab)");
    }

    if (
      profile.permSites.length === 0 &&
      profile.parker.stripeMetadata === null
    ) {
      t.push("Add a payment method (see Details tab)");
    }

    if(profile.parker.hasExpiredPayment) {
      t.push("Update your payment method (see Details tab)");
    }

    setTasks([...t]);
  }, [profile]);

  return (
    <>
      {tasks.length > 0 && (
        <div className="container">
          <div className="alert alert-danger">
            <div>Please do the following to complete your profile</div>
            <ul className="mt-3">
              {tasks.map((task, index) => (
                <li key={"task" + index}>{task}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect, FC, ComponentProps } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import ButtonWrapper from "./ButtonWrapper";
import { Profile, useProfile } from "../auth/authContext";
import { useApi, Vehicle } from "../api/shareparkApi";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const Vehicles: FC = () => {
  const profile = useProfile();

  let { id } = useParams();
  const navigate = useNavigate();

  const onVehicleClicked = (vehicle: Vehicle) => {
    navigate(`/vehicles/${vehicle.id}`);
  };

  const showNewEditForm = () => {
    navigate(`/vehicles/00000000-0000-0000-0000-000000000000`);
  };

  return (
    <>
      <Container className="px-5">
        <Row className="g-0 mt-5">
          <Col className="px-2">
            <Container>
              <Row className="mb-2">
                <Col>
                  <h2>Your Vehicles</h2>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6} style={{ width: "185px" }}>
                  <ButtonWrapper
                    buttonText="+ Add"
                    fullWidth={true}
                    onClick={() => showNewEditForm()}
                  />
                </Col>
              </Row>
              {profile.vehicles.map((vehicle, i) => (
                <Row key={i}>
                  <Col>
                    <VehicleDescription
                      vehicle={vehicle}
                      onVehicleClicked={() => onVehicleClicked(vehicle)}
                    />
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      {id && <VehicleEditForm show={true} profile={profile} />}
    </>
  );
};

const VehicleDescription: FC<{
  vehicle: Vehicle;
  onVehicleClicked: () => void;
}> = ({ vehicle, onVehicleClicked }) => {
  return (
    <Container className="g-0 my-2">
      <Row onClick={onVehicleClicked} className="g-0 py-1 curserPointer">
        <Col
          md
          sm={12}
          className="px-2 py-1"
          style={{ backgroundColor: "#d8d7d7" }}
        >
          <Row>
            <Col md={12} sm={4}>
              Lic. Plate
            </Col>
            <Col style={{ fontSize: "21px", fontWeight: "bold" }}>
              {vehicle["plate"]}
            </Col>
          </Row>
        </Col>
        <Col
          md
          sm={12}
          className="px-3 py-1"
          style={{ backgroundColor: "white" }}
        >
          <Row>
            <Col md={12} sm={4} className="vehicleHeading">
              Make
            </Col>
            <Col className="biggerBlackText">{vehicle["make"]}</Col>
          </Row>
        </Col>
        <Col
          md
          sm={12}
          className="px-3 py-1"
          style={{ backgroundColor: "white" }}
        >
          <Row>
            <Col md={12} sm={4} className="vehicleHeading">
              Model
            </Col>
            <Col className="biggerBlackText">{vehicle["model"]}</Col>
          </Row>
        </Col>
        <Col
          md
          sm={12}
          className="px-3 py-1"
          style={{ backgroundColor: "white" }}
        >
          <Row>
            <Col md={12} sm={4} className="vehicleHeading">
              Colour
            </Col>
            <Col className="biggerBlackText">{vehicle["colour"]}</Col>
          </Row>
        </Col>
        <Col
          md
          sm={12}
          className="px-3 py-1"
          style={{ backgroundColor: "white" }}
        >
          <Row>
            <Col md={12} sm={4} className="vehicleHeading">
              State
            </Col>
            <Col className="biggerBlackText">{vehicle["state"]}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const VehicleEditForm: FC<
  { profile: Profile } & ComponentProps<typeof Modal>
> = ({ profile, ...props }) => {
  var states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

  const { id } = useParams();
  const navigate = useNavigate();

  const vehicle = profile?.vehicles.find((v) => v.id === id);

  const { editVehicle, createVehicle, deleteVehicle } = useApi();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty, errors },
  } = useForm<Vehicle>({ defaultValues: { ...vehicle } });

  const onSaveVehicle = (data: Vehicle) => {
    if (id === "00000000-0000-0000-0000-000000000000") {
      createVehicle(data);
    } else {
      editVehicle(data);
    }

    navigate("/vehicles");
  };

  const onDeleteVehicle = () => {
    if (id !== "00000000-0000-0000-0000-000000000000") {
      deleteVehicle(vehicle as Vehicle);
    }
    navigate("/vehicles");
  };

  return (
    <Modal
      {...props}
      dialogClassName="modalVehicleEditForm"
      centered
      backdrop="static"
    >
      <Modal.Header style={{ backgroundColor: "white" }}>
        <Col>
          <h2>Vehicle</h2>
        </Col>
        <Col xs={3} className="text-end">
          <ButtonWrapper
            buttonText="Close x"
            fullWidth={true}
            onClick={() => navigate("/vehicles")}
          />
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <form onSubmit={handleSubmit(onSaveVehicle)}>
            {vehicle?.id !== "00000000-0000-0000-0000-000000000000" && (
              <Row>
                <Col xs={6}>
                  <ButtonWrapper
                    buttonText="Delete Vehicle"
                    fullWidth={true}
                    onClick={() => onDeleteVehicle()}
                  />
                  <br />
                  <br />
                </Col>
              </Row>
            )}
            <Row className="formStyle">
              <Col>
                <Row>
                  <Form.Group as={Col} controlId="vehiclelicencePlate">
                    <Form.Label>Licence Plate*</Form.Label>
                    <Form.Control {...register("plate")} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="vehicleMake">
                    <Form.Label>Make</Form.Label>
                    <Form.Control {...register("make")} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="vehicleModel">
                    <Form.Label>Model</Form.Label>
                    <Form.Control {...register("model")} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="isElectric">
                    <Form.Label>Electric Vehicle?</Form.Label>
                    <Form.Check
                      {...register("isElectric")}
                      type="checkbox"
                      label=""
                      defaultChecked={vehicle?.isElectric}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="vehicleColour">
                    <Form.Label>Colour</Form.Label>
                    <Form.Control {...register("colour")} />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="vehicleState">
                    <Form.Label>State of Registration</Form.Label>
                    <Form.Select
                      {...register("state")}
                      className="select-style"
                    >
                      <option style={{ display: "none" }}></option>
                      {states.map((state, i) => (
                        <option key={i} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <Col xs={6}>
                    <button className="orangeButton fullWidth" type="submit">
                      Save
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Vehicles;

import React, { FC } from "react";
import { uiConfig } from "./firebase";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth, firebaseAuth } from "./authContext";
import StyledFirebaseAuth from "./StyledFirebaseAuth";

type MaybeStateWithFrom = { from: { pathname: string } } | null;

export const Login: FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const state = (location.state as MaybeStateWithFrom) ?? {
    from: { pathname: "/" },
  };
  const { from } = state;

  return (
    <div
      id="login"
      className="d-flex justify-content-center overflow-hidden min-vh-100"
    >
      {currentUser ? (
        <Navigate replace to={from} />
      ) : (
        <div className="d-flex align-items-center">
          <div className="card">
            <div className="card-header">
              <h4 className="text-light">
                Login or Signup{" "}
                <img
                  alt="SharePark logo"
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjk1NyAwbDEwLjQ3NSAxMC40NzVMMTAuOTU3IDIwLjk1bC0zLjE4Mi0zLjE4MiA1LjA0My01LjA0M0gwdi00LjVoMTIuODE4TDcuNzc1IDMuMTgyIDEwLjk1NyAweiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg=="
                />
              </h4>
            </div>
            <div className="card-body">
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebaseAuth}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React, { FC } from "react";
import "../custom.css";

const ButtonWrapper: FC<{
  onClick: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  buttonText: string;
}> = ({ onClick, fullWidth, disabled, buttonText }) => {
  const fullWidthClass = fullWidth ? " fullWidth" : "";
  const disabledButton = disabled ? " disabledButton" : "";

  return (
    <button
      disabled={!!disabled}
      type="button"
      onClick={onClick}
      className={"orangeButton" + fullWidthClass + disabledButton}
    >
      {buttonText}
    </button>
  );
};

export default ButtonWrapper;

// added to support React 18
// inspiration from: https://github.com/firebase/firebaseui-web-react/pull/173#issuecomment-1151532176
import { FC, useEffect, useRef, useState } from "react";
import { Auth, onAuthStateChanged } from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

interface Props {
  uiConfig: firebaseui.auth.Config;
  firebaseAuth: Auth;
}

const StyledFirebaseAuth: FC<Props> = ({ uiConfig, firebaseAuth }) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);
    if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset();

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) {
        firebaseUiWidget.reset();
      }
      setUserSignedIn(!!user);
    });

    // Render the firebaseUi Widget.
    // @ts-ignore
    firebaseUiWidget.start(elementRef.current, uiConfig);

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [firebaseAuth, uiConfig, userSignedIn]);

  return <div ref={elementRef} />;
};

export default StyledFirebaseAuth;
